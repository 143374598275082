import React, { FC } from "react";
import Link from "next/link";
import { SupplierCardFragment } from "@graphql/types";
import { useRouter } from "next/router";
import { t } from "@lingui/macro";
import { Card } from "@components/ui/Card";
import { Rating } from "@components/ui/Rating";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";

type Props = SupplierCardFragment;

const SupplierCard: FC<Props> = ({ ...user }) => {
  const router = useRouter();
  const { query } = router;

  let supplierUrl = user.url;
  if (
    (query.t === undefined || query.t === "guides") &&
    (query.date !== "" || query.people !== "")
  ) {
    const queryParams = {
      ...(query.date !== undefined && { date: query.date }),
      ...(query.adults !== undefined && { adults: query.adults }),
      ...(query.children !== undefined && { children: query.children }),
      ...(query.infants !== undefined && { infants: query.infants }),
    };
    const queryString = new URLSearchParams(queryParams as any).toString();
    supplierUrl = queryString ? `${supplierUrl}?${queryString}` : supplierUrl;
  }

  return (
    <Link href={getAbsoluteUrl(supplierUrl)}>
      <a
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
        target="_blank"
        className="flex flex-1"
      >
        <Card className="flex flex-col flex-1 mb-0">
          <Card.Image
            key={user.id}
            src={user.picture && user.picture.url}
            alt={user.city?.name + " Tour Guide - " + user.displayName}
          />
          <Card.Container className="flex flex-1 flex-col">
            <Card.Body className="flex flex-col flex-1">
              <Card.Title>{user.displayName}</Card.Title>
              {user.stats.rating > 0 && user.stats.reviewsCount > 0 && (
                <Rating
                  rate={user.stats.rating}
                  reviews={user.stats.reviewsCount}
                  alwaysShowDecimals={true}
                  variant="stars"
                  className="mb-2"
                />
              )}
              <div className="mt-auto">
                {user.country?.name && (
                  <div>
                    <Card.Tag>{user.country?.name}</Card.Tag>
                  </div>
                )}
                <Card.Description className="truncate-4 text-clip mt-2 mb-0">
                  {user.biography && user.biography}
                  {/* fake space */}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {/* fake space */}
                </Card.Description>
              </div>
            </Card.Body>
          </Card.Container>
        </Card>
      </a>
    </Link>
  );
};

export default SupplierCard;
